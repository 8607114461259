<template>
  <v-container fluid>
    <v-card>
      <v-row class="align-center pt-3">
        <h1 class="mx-7">PRECIO MEDIO BODAS POR ESPACIOS</h1>
        <v-select hide-details="" style="max-width: 100px;" filled dense class="v-superdense-input" :items="years" v-model="selectedYear" @change="getEspacios($event)"></v-select>
      </v-row>
      <v-card-text class="d-flex flex-wrap justify-space-between">
        <v-simple-table style="width: min-content;">
          <thead>
            <tr>
              <th style="white-space: nowrap;">ESPACIO</th>
              <th style="white-space: nowrap;">VENTAS</th>
              <th style="white-space: nowrap;">BENEFICIO</th>
              <th style="white-space: nowrap;">MARGEN (%)</th>
              <th style="white-space: nowrap;">ADULTOS</th>
              <th style="white-space: nowrap;">PM PERSONA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(b,i) in bodasEsp">
              <td style="white-space: nowrap">{{ b.title }}</td>
              <td style="white-space: nowrap" class="text-right">{{ parseFloat(b.ventas).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</td>
              <td style="white-space: nowrap" class="text-right">{{ parseFloat(b.beneficio).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</td>
              <td style="white-space: nowrap" class="text-right">{{ parseFloat(b.beneficio / (b.ventas || 1) *100).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} %</td>
              <td style="white-space: nowrap">{{ parseFloat(b.adultos).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              <td style="white-space: nowrap" class="text-right">{{ parseFloat(b.pmPers).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</td>
            </tr>
            <tr>
              <td>TOTAL</td>
              <td style="white-space: nowrap" class="text-right">{{ parseFloat(totalEsp.ventas || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</td>
              <td style="white-space: nowrap" class="text-right">{{ parseFloat(totalEsp.beneficio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</td>
              <td style="white-space: nowrap" class="text-right">{{ parseFloat(totalEsp.beneficio/(totalEsp.ventas || 1)*100).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} %</td>
              <td style="white-space: nowrap">{{ parseFloat(bodasEsp.reduce((sum, a) => sum + a.adultos, 0) / (bodasEsp.length || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              <td style="white-space: nowrap" class="text-right">{{ parseFloat(bodasEsp.reduce((sum, a) => sum + a.pmPers, 0) / (bodasEsp.length || 1)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text style="min-height: 400px;" class="d-flex flex-row">
        <div class="graph text-right me-16" id="div1">
          <canvas style="min-height: 400px;" id="graph1"></canvas>
        </div>
        <div class="graph text-right" id="div2">
          <canvas style="min-height: 400px;" id="graph2"></canvas>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  data(){
    return {
      specialColorBorder:{
        success: 'rgba(76, 175, 80, 1)',
        info: 'rgba(54, 162, 235, 1)',
        warning: 'rgba(251, 140, 0, 1)',
        error: 'rgba(255, 82, 82, 1)'
      },
      specialColor:{
        success: 'rgba(76, 175, 80, 0.2)',
        info: 'rgba(54, 162, 235, 0.2)',
        warning: 'rgba(251, 140, 0, 0.2)',
        error: 'rgba(255, 82, 82, 0.2)'
      },
      selectedYear: null,
      years: [],
      eventos: [],
      bodasEsp: [],
      bodasY: [],
      totalEsp: {
        ventas : 0,
        beneficio : 0,
      },
      totalY: {
        ventas : 0,
        beneficio : 0,
      },
      borderColors:[
        'rgba(181, 20, 66, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 205, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(0, 0, 207, 1)',
        'rgba(64, 255, 88, 1)',
      ],
      colors:[
        'rgba(181, 20, 66, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(0, 0, 207, 0.2)',
        'rgba(64, 255, 88, 0.2)',
      ]
    }
  },
  methods:{
    async getYearsList(){
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/financiero/years`,
      });
      this.years = data.map(y => y.y)
    },
    // async getYears(){
      // this.bodasY = []
      // this.totalY = {
      //   ventas : 0,
      //   beneficio : 0,
      // }
      // this.years.forEach(y => {
      //   this.bodasY.push({title : y, ...this.getAvg(this.eventos.filter(e => new Date(e.date).getFullYear() == y))})
      // });


      // var ds = []
      // this.bodasEsp.forEach((r,i) => {
      //   ds.push({ 
      //     label : r.title,
      //     data : [r.ventas,r.beneficio,parseFloat(r.beneficio / (r.ventas || 1) * 100).toFixed(2),parseFloat(r.adultos).toFixed(2), parseFloat(r.pmPers).toFixed(2)],
      //     backgroundColor: [
      //       this.colors[i],
      //     ],
      //     borderColor: [
      //       this.borderColors[i],
      //     ],
      //     borderWidth: 1,
      //   })
      // });
      // this.selectedYear = this.years[0]
    // },
    async getEspacios(y = this.selectedYear){
      this.bodasEsp = []
      this.totalEsp = {
        ventas : 0,
        beneficio : 0,
      }
      if(this.chart1) this.chart1.destroy()
      if(this.chart2) this.chart2.destroy()
      if (y){
        this.bodasEsp.push({title : 'MASMONZON', ...this.getAvg(this.eventos.filter(e => (e.custom_key.split('_')[0]).split(' - ')[0] == "MASMONZON" && new Date(e.date).getFullYear() == y), true)})
        this.bodasEsp.push({title : 'LALANNE', ...this.getAvg(this.eventos.filter(e => (e.custom_key.split('_')[0]).split(' - ')[0] == "LALANNE" && new Date(e.date).getFullYear() == y), true)})
        this.bodasEsp.push({title : 'LAUS', ...this.getAvg(this.eventos.filter(e => (e.custom_key.split('_')[0]).split(' - ')[0] == "LAUS" && new Date(e.date).getFullYear() == y), true)})
        this.bodasEsp.push({title : 'CATERING', ...this.getAvg(this.eventos.filter(e => (e.custom_key.split('_')[0]).split(' - ')[0] == "CATERING" && new Date(e.date).getFullYear() == y), true)})
      }
      else{
        this.bodasEsp.push({title : 'MASMONZON', ...this.getAvg(this.eventos.filter(e => (e.custom_key.split('_')[0]).split(' - ')[0] == "MASMONZON"),true)})
        this.bodasEsp.push({title : 'LALANNE', ...this.getAvg(this.eventos.filter(e => (e.custom_key.split('_')[0]).split(' - ')[0] == "LALANNE"),true)})
        this.bodasEsp.push({title : 'LAUS', ...this.getAvg(this.eventos.filter(e => (e.custom_key.split('_')[0]).split(' - ')[0] == "LAUS"),true)})
        this.bodasEsp.push({title : 'CATERING', ...this.getAvg(this.eventos.filter(e => (e.custom_key.split('_')[0]).split(' - ')[0] == "CATERING"),true)})
      }

      var ds = []
      console.log(this.bodasEsp);
      this.bodasEsp.forEach((r,i) => {
        ds.push(
          { 
            label : r.title,
            data : [r.ventas],
            backgroundColor: [
              this.colors[i],
            ],
            borderColor: [
              this.borderColors[i],
            ],
            borderWidth: 1,
            datalabels: {
              color: this.borderColors[i],
              align: 'end',
              anchor: 'start',
              clamp: true
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  return parseFloat(context.formattedValue).toFixed(2).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")
                }
              }
            }
          }
        )
        ds.push(
          { 
            label : r.title,
            data : [parseFloat((r.beneficio / (r.ventas || 1)) * 100).toFixed(2)],
            backgroundColor: [
              this.colors[i],
            ],
            borderColor: [
              this.borderColors[i],
            ],
            borderWidth: 1,
            datalabels: {
              color: this.borderColors[i],
              align: 'end',
              anchor: 'start',
              clamp: true
            },
            // tooltip: {
            //   callbacks: {
            //     label: function(context) {
            //       if (context.dataIndex == 1) return r.beneficio
            //       return context.formattedValue
            //     }
            //   }
            // }
          }
        )
      });

      this.chart1 = new Chart(document.getElementById("graph1"), {
        data: {
          plugins: [ChartDataLabels],
          labels: this.bodasEsp.map(r => r.title),
          datasets: [
            {
              type:'bar',
              label: 'VENTAS',
              tension: 0,
              data: this.bodasEsp.map(r => parseFloat(r.ventas).toFixed(2)),
              backgroundColor: [
                this.specialColor["info"],
              ],
              borderColor: [
                this.specialColorBorder["info"],
              ],
              backgroundColor: this.specialColor["info"],
              borderWidth: 1,
              yAxisID: 'y',
              datalabels: {
                color: this.specialColorBorder['info'],
                align: 'start',
                anchor: 'start',
                clamp: true
              }
            },
            {
              type:'line',
              label: '% BENEFICIO',
              tension: 0,
              data: this.bodasEsp.map(r => parseFloat((r.beneficio/(r.ventas || 1))*100).toFixed(2)),
              backgroundColor: [
                this.specialColor['warning'],
              ],
              borderColor: [
                this.specialColorBorder['warning'],
              ],
              backgroundColor: this.specialColor['warning'],
              borderWidth: 1,
              yAxisID: 'y2',
              datalabels: {
                color: this.specialColorBorder['warning'],
                align: 'end',
                anchor: 'start',
                clamp: true,
              },
            }
          ]
        },
        options: {
          plugins: {
            title: {
              color: '#d39652',
              display: true,
              text: 'VENTAS / % BENEFICIO',
              position: 'top',
              align:'center',
              font: {
                size: 24,
              },
            },
            datalabels:{
              formatter: function(value, context) {
                return value.replaceAll(".",",").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
              }
            }
          },
          maintainAspectRatio: false,
          // plugins: {
          //   title: {
          //     color: '#d39652',
          //     display: true,
          //     text: 'PRECIO MEDIO POR ESPACIO',
          //     position: 'top',
          //     align:'center',
          //     font: {
          //       size: 24,
          //     }
          //   }
          // },
          scales: {
            y: {
              ticks: {
                color: this.specialColorBorder['info'],
                display: true
              },
              beginAtZero: true,
            },
            y2: {
              position: 'right',
              ticks: {
                color: this.specialColorBorder['warning']
              },
              grid: {
                drawOnChartArea: false // only want the grid lines for one axis to show up
              },
              beginAtZero: true,
            },
            x:{
              ticks: {
                padding: 15
              }
            },
          },
        },
        plugins: [ChartDataLabels],
      });



      this.chart2 = new Chart(document.getElementById("graph2"), {
        data: {
          plugins: [ChartDataLabels],
          labels: this.bodasEsp.map(r => r.title),
          datasets: [
            {
              type:'bar',
              label: 'ADULTOS',
              tension: 0,
              data: this.bodasEsp.map(r => parseFloat(r.adultos).toFixed(2)),
              backgroundColor: [
                this.specialColor["info"],
              ],
              borderColor: [
                this.specialColorBorder["info"],
              ],
              backgroundColor: this.specialColor["info"],
              borderWidth: 1,
              yAxisID: 'y',
              datalabels: {
                color: this.specialColorBorder['info'],
                align: 'end',
                anchor: 'start',
                clamp: true
              }
            },
            {
              type:'line',
              label: 'PM ADULTOS',
              tension: 0,
              data: this.bodasEsp.map(r => parseFloat(r.pmPers).toFixed(2)),
              backgroundColor: [
                this.specialColor['warning'],
              ],
              borderColor: [
                this.specialColorBorder['warning'],
              ],
              backgroundColor: this.specialColor['warning'],
              borderWidth: 1,
              yAxisID: 'y2',
              datalabels: {
                color: this.specialColorBorder['warning'],
                align: 'start',
                anchor: 'start',
                clamp: true,
              }
            }
          ]
        },
        options: {
          // responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              color: '#d39652',
              display: true,
              text: 'TOTAL COMENSALES',
              position: 'top',
              align:'center',
              font: {
                size: 24,
              },
            },
          },
          scales: {
            x:{
              ticks: {
                padding: 15
              }
            },
            y: {
              ticks: {
                color: this.specialColorBorder['info'],
                display: true
              },
              beginAtZero: true,
            },
            y2: {
              position: 'right',
              ticks: {
                color: this.specialColorBorder['warning']
              },
              grid: {
                drawOnChartArea: false // only want the grid lines for one axis to show up
              },
              beginAtZero: true,
            }
          }
        },
        plugins: [ChartDataLabels],
      })

      // this.chart3 = new Chart(document.getElementById("graph3"), {
      //   data: {
      //     labels: this.bodasEsp.map(r => r.title),
      //     datasets: [
      //       {
      //         type: 'bar',
      //         label: 'ADULTOS',
      //         tension: 0,
      //         data: this.bodasEsp.map(r => parseFloat(r.adultos).toFixed(2)),
      //         backgroundColor: [
      //           this.specialColor["info"],
      //         ],
      //         borderColor: [
      //           this.specialColorBorder["info"],
      //         ],
      //         backgroundColor: this.specialColor["info"],
      //         borderWidth: 1,
      //         datalabels: {
      //           color: this.specialColorBorder['info'],
      //           align: 'start',
      //           anchor: 'end',
      //           clamp: true
      //         },
      //         yAxisID: 'y',
      //       },
      //       {
      //         type:'line',
      //         label: 'PM ADULTOS',
      //         tension: 0,
      //         data: this.bodasEsp.map(r => parseFloat(r.pmPers).toFixed(2)),
      //         backgroundColor: [
      //           this.specialColor['warning'],
      //         ],
      //         borderColor: [
      //           this.specialColorBorder['warning'],
      //         ],
      //         backgroundColor: this.specialColor['warning'],
      //         borderWidth: 1,
      //         datalabels: {
      //           color: this.specialColorBorder['warning'],
      //           align: 'end',
      //           anchor: 'start',
      //           clamp: true
      //         },
      //         yAxisID: 'y2',
      //       }
      //     ]
      //   },
      //   options: {
      //     plugins: {
      //       title: {
      //         color: '#d39652',
      //         display: true,
      //         text: 'TOTAL COMENSALES',
      //         position: 'top',
      //         align:'center',
      //         font: {
      //           size: 24,
      //         },
      //       }
      //     },
      //     scales: {
      //       y: {
      //         ticks: {
      //           color: this.specialColorBorder['info'],
      //           display: true
      //         },
      //         beginAtZero: true,
      //       },
      //       y2: {
      //         position: 'right',
      //         ticks: {
      //           color: this.specialColorBorder['warning']
      //         },
      //         grid: {
      //           drawOnChartArea: false // only want the grid lines for one axis to show up
      //         },
      //         beginAtZero: true,
      //       }
      //     },
      //     maintainAspectRatio: false,
      //     // plugins: {
      //     //   title: {
      //     //     color: '#d39652',
      //     //     display: true,
      //     //     text: 'PRECIO MEDIO POR ESPACIO',
      //     //     position: 'top',
      //     //     align:'center',
      //     //     font: {
      //     //       size: 24,
      //     //     }
      //     //   }
      //     // },
      //     scales: {
      //       y: {
      //         stacked: true,
      //         beginAtZero: false,
      //       },
      //       x: {
      //         stacked: true
      //       }
      //     },
      //   },
      //   plugins: [ChartDataLabels],
      // });
    },
    async getEventos() {
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/financiero/avgEsp/1`,
      });

      this.eventos = data
      this.eventos = this.eventos.filter(e => e.datos != null)
    },
    getAvg(arr, esp = false){

      var b = {
        n : 0,
        ventas : 0,
        beneficio : 0,
        margen : 0,
        adultos : 0,
        pmPers : 0,
      }

      arr.forEach(coste => {
        console.log(coste.rentabilidad)
        b.n++
        // coste.totalVentas = Math.round(+coste.datos.minuta.ventasFnB + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)
        coste.totalVentas = Math.round(+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)
        // if (coste.lugar.split("-")[0] == "PISCIS ") console.log(coste.totalVentas);
        // if (coste.lugar.split("-")[0] == "PISCIS ") console.log(coste);
        var totalCatering = coste.datos.costesCatering.reduce((acc, v) => (typeof acc === 'object') ? acc.precio += +v.precio : +acc + +v.precio, 0);
        var totalOce = 0
        totalOce += coste.datos.cuestionario.fijo.filter(n => n.aplica == true).reduce((acc, v) => acc + v.precio, 0);
        totalOce += coste.datos.cuestionario.variable.filter(n => n.aplica == true).reduce((acc, v) => acc + (v.precio * ((coste.nNinos || 0) + (coste.nAdultos || 0))), 0);
        coste.datos.otrosExtras.forEach(coste => {
          totalOce += +coste.precio;
        });
        totalOce += ((Math.round((+((isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) * (1 / 100)) + Number.EPSILON) * 100) / 100));
        totalOce += (2.5 * (+coste.nAdultos + +coste.nNinos));
        totalOce += ((+coste.nAdultos + +coste.nNinos) * 0.4);
        coste.bNeto = Math.round((((+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) - (+((+coste.datos.equipo.camareros + +coste.datos.equipo.otros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.cocineros) +
                      +(Math.round(+(+((isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
                      +
                      +(Math.round(((+(+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
                      +
                      +totalOce
                      +
                      +(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision)
                      +
                      +totalCatering
                      +
                      +(Math.round(((+(+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100))))

        coste.mNeto = (((((((+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos)) - (+((+coste.datos.equipo.camareros + +coste.datos.equipo.otros + +coste.datos.equipo.senoras + +coste.datos.equipo.bodegueros + +coste.datos.equipo.cocineros) +
                      +(Math.round(+(+((isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) * (12/100)) + Number.EPSILON) * 100) / 100) +
                      +(Math.round(+(+(coste.datos.minuta.servicioCatering * (35/100)) + Number.EPSILON) * 100) / 100)) 
                      +
                      +(Math.round(((+(+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) * +(+(+coste.datos.FnB.pntCosteBeberageFijo + +coste.datos.FnB.pntCosteBeberage + +coste.datos.FnB.pntCosteFoodFijo + +coste.datos.FnB.pntCosteFood) / 100))) + Number.EPSILON) * 100) / 100)
                      +
                      +totalOce
                      +
                      +(+coste.datos.minuta.alquileresEspacio + +coste.datos.alquiler.comision)
                      +
                      +totalCatering
                      +
                      +(Math.round(((+(+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) * +(+(+coste.datos.general.pntCosteFijo + +coste.datos.general.pntCosteVariable) / 100))) + Number.EPSILON) * 100) / 100)))) / (((+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos) == 0 ? 1 : (+(isNaN(coste.datos.minuta.ventasFnB) ? 0 : coste.datos.minuta.ventasFnB) + +coste.datos.minuta.alquileresEspacio + +coste.datos.minuta.servicioCatering + +coste.datos.minuta.serviciosExternos))))) * 100).toFixed(2)
        
        coste.pVtaPersona = Math.round(coste.totalVentas / Math.max(coste.nAdultos || 1))
        
        b.ventas += parseFloat(coste.totalVentas || 0)
        b.beneficio += parseFloat(coste.bNeto || 0)
        b.adultos += parseFloat(coste.nAdultos || 0)
        b.pmPers += parseFloat(coste.pVtaPersona || 0)
      });
      if(esp){
        this.totalEsp.ventas += b.ventas
        this.totalEsp.beneficio += b.beneficio
      }
      else{
        this.totalY.ventas += b.ventas
        this.totalY.beneficio += b.beneficio
      }
      b.adultos = parseFloat(b.adultos / (b.n || 1))
      b.pmPers = parseFloat(b.pmPers / (b.n || 1))
      return b
    }
  },
  async mounted(){
    this.loading = true
    await Promise.all([
      await this.getYearsList(),
      await this.getEventos(),
      await this.getEspacios(),
      // await this.getYears(),
    ]);
    this.loading = false
  }
};
</script>

<style>
.graph {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  height:350px !important; width: 45% !important;
}
.graph > canvas {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  max-height:300px !important; width: 100% !important;
}
.graphExtended {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  height: 800px !important; width: 100% !important;
}
.graphExtended > canvas {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  max-height:750px !important; width: 100% !important;
}
</style>